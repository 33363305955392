import React from 'react';
import Image from 'next/image';
import Links from '../links';

const Career = ({widgetData = {}}) => {
  const {Title, Items, BackgroundColor} = widgetData;

  return (
    <div
      style={{
        backgroundColor: BackgroundColor,
      }}>
      <div className="flex flex-col lg:w-[952px] lg:py-10 pt-8 max-md:pb-10 px-4 lg:px-0 lg:ml-auto lg:mr-auto">
        <h1 className="text-left text-primary lg:text-[32px] text-[32px] font-boldie lg:leading-[56px] leading-[44px] lg:tracking-[0.8px] tracking-[0.64px] lg:pb-8 pb-4">
          {Title}
        </h1>
        {Items.map(({Logo, Url, Text}, index) => (
          <div key={index} className="border flex justify-between border-solid border-[#E5E5E5] lg:px-[72px] lg:py-[33px] px-4 py-4 lg:mb-5">
            <div className="lg:w-[130px] lg:h-[38px] w-[85px] h-[20px]">
              <Image src={Logo} alt="career-logo" width={0} height={0} sizes="100vw" style={{width: '100%', height: 'auto'}} />
            </div>
            <Links
              href={Url}
              className="text-primary underline text-[16px] lg:text-[20px] font-boldie leading-[34px] tracking-[0.32px] lg:tracking-[0.48px] max-sm:max-w-[174px]">
              {Text}
            </Links>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Career;
