import PropTypes from 'prop-types';
import Image from 'next/image';
import Drawer from '../drawer';

const OurRecentInnovationsInventionsDrawer = ({isOpen, setIsOpen, selected}) => {
  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="flex lg:ml-12 ml-0 h-full">
        <div className="lg:flex-1 lg:mr-6 mr-0 lg:mb-[67px] mb-0 overflow-hidden flex flex-col max-sm:overflow-y-auto max-sm:relative max-sm:no-scrollbar">
          <div className="flex-grow-0 lg:pt-[72px] pt-0 lg:pb-[58px] pb-0">
            <div className="hidden max-sm:block mb-10">
              <Image src={selected.imageLink} width={375} height={370} alt=""></Image>
            </div>

            <p className="mb-6 ml-6 lg:ml-0 lg:mb-0 lg:text-[40px] text-[32px] font-boldie lg:leading-[56px] leading-[44px] lg:tracking-[0.8px] tracking-[0.64px] text-secondary">
              {selected.title}
            </p>
          </div>

          <div className="lg:overflow-y-auto lg:relative lg:no-scrollbar flex lg:flex-1 pl-6 lg:pl-0">
            <p className="lg:text-[20px] text-[16px] leading-[22px] lg:leading-7 tracking-[0.32px] lg:tracking-[0.4px] text-primary relative z-10">
              {selected.drawerContent}
            </p>
            <div className="sticky bottom-0 left-0 bg-white bg-opacity-50 w-full h-10 z-"></div>
          </div>

          <div className="flex-row mb-[72px] mt-10 lg:mt-3 pl-6 lg:pl-0">
            <i className="icon youtube text-primary text-[32px]"></i>
            <i className="icon instagram text-primary text-[32px] ml-4"></i>
            <i className="icon twitter text-primary text-[32px] ml-4"></i>
          </div>
        </div>

        <div className="hidden lg:flex-1 sm:block">
          <Image src={selected.drawerImageLink} width={476} height={1039} alt=""></Image>
        </div>
      </div>
    </Drawer>
  );
};

export default OurRecentInnovationsInventionsDrawer;

PropTypes.Drawer = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  selected: PropTypes.object,
};
