import Image from 'next/image';
import 'react-circular-progressbar/dist/styles.css';
import Links from '../links';

const NavigationList = ({widgetData = {}}) => {
  const {Items, Image: displayImage} = widgetData;

  return (
    <div className="flex flex-col md:flex-row relative">
      <div className="relative hidden sm:block lg:flex-1">{displayImage && <Image src={displayImage} width={855} height={993} alt=""></Image>} </div>
      <div className="bg-white lg:flex-1 mt-[72px] sm:-mt-[197px]">
        <div className=" flex flex-col justify-items-center ml-6 mr-6 lg:mr-0 mb-[72px] lg:mb-0 lg:ml-[146px] gap-12 lg:mt-[120px] lg:w-[571px] lg:text-[32px] text-[24px] leading-[34px] tracking-[.34px] lg:tracking-[0.64px] lg:whitespace-nowrap">
          {Items?.map((item, index) => (
            <Links key={index} className="flex items-center text-primary" href={`${item?.Url}`}>
              {item?.Text} <i className="icon text-shape-right text-active text-[12px] ml-3"></i>
            </Links>
          ))}
        </div>
      </div>
      <div className="hidden max-sm:block xs:flex flex-col">
        <Image src={displayImage} layout="fill" objectFit="cover" alt=""></Image>
      </div>
    </div>
  );
};

export default NavigationList;
