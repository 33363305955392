import classNames from 'classnames';
import {useRouter} from 'next/router';
import {useGetHeaderQuery} from '@/store/services';
import Links from '../links';
import BreadCrumbDropdown from './dropdown';
import {selectedLanguage} from '@/hooks/LanguageContext';

const BreadCrumb = ({className}) => {
  const router = useRouter();
  const {data} = useGetHeaderQuery(selectedLanguage ? selectedLanguage : 'tr');

  const url = router.asPath.split('?')[0];
  const roots = data?.map(item => item.Items).flat();
  const root = roots?.find(item => item.Url === url);
  const childs = roots?.map(item => item.Items).flat();
  const child = childs?.find(item => item.Url === url);
  const rootOfAChild = roots?.find(item => item.Items.includes(child));

  const isRoot = root ? true : false;
  const isChild = child ? true : false;

  const breadcrumbs = () => {
    const data = [
      {
        name: 'Anasayfa',
        url: '/',
      },
    ];

    if (isRoot) {
      data.push({
        name: root.Title,
        url: root.Url,
      });
    } else if (isChild) {
      if (rootOfAChild) {
        data.push({
          name: rootOfAChild.Title,
          url: rootOfAChild.Url,
        });
      }

      data.push({
        name: child.Text,
        url: child.Url,
      });
    }

    return data;
  };

  const breadcrumbsSize = breadcrumbs().length;

  const dropdownOptions = () => {
    let links = [];

    if (isRoot) {
      links = roots?.map(item => {
        return {name: item.Title, url: item.Url};
      });
    } else if (isChild) {
      links = rootOfAChild?.Items?.map(item => {
        return {name: item.Text, url: item.Url};
      });
    }

    return links;
  };

  return (
    <div className="hidden sm:block">
      <div className={classNames(className, 'flex justify-center items-center mt-8')}>
        {breadcrumbs().map(({name, url}, index) => (
          <div
            key={index}
            className={classNames(
              "flex items-start gap-3 text-[14px] after:content-['/'] after:mx-[10px] z-10 last-of-type:after:content-none text-white",
              {
                '!text-[#999999]': !url,
              },
            )}>
            {breadcrumbsSize === index + 1 ? (
              <BreadCrumbDropdown options={dropdownOptions()} label={name} />
            ) : url ? (
              <Links href={url}>{name}</Links>
            ) : (
              name
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BreadCrumb;
