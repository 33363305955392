import React from 'react';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {useGetHeaderQuery} from '@/store/services';
import Links from '../links';
import {selectedLanguage} from '@/hooks/LanguageContext';

const NavigationCategory = ({widgetData = {}}) => {
  const router = useRouter();
  const {data} = useGetHeaderQuery(selectedLanguage ? selectedLanguage : 'tr');
  const {WebImage, MobileImage} = widgetData;
  const roots = data?.map(item => item.Items).flat();
  const navigationItem = roots?.find(item => item.Url === router.asPath);
  const navigationItems = navigationItem?.Items;

  return (
    <div className="flex max-lg:flex-col relative">
      <div className="relative max-lg:hidden flex-1">
        <Image
          src={WebImage}
          width={0}
          height={0}
          sizes="100vw"
          objectFit="cover"
          style={{width: '100%', height: '100%'}}
          alt="navigation-category"
        />
      </div>

      <div className="bg-white flex-1 pt-[72px] pb-[72px] lg:pt-0 mt-0 lg:-mt-[197px]">
        <div className="flex flex-col justify-items-center ml-6 mr-6 lg:mr-0 lg:mb-0 lg:ml-[146px] gap-12 lg:mt-[120px] lg:text-[32px] text-[24px] leading-[34px] tracking-[.34px] lg:tracking-[0.64px] lg:whitespace-nowrap">
          {navigationItems?.map((item, index) => (
            <Links key={index} className="flex items-center text-primary" href={item?.Url}>
              {item.Text} <i className="icon text-shape-right text-active text-[12px] ml-3"></i>
            </Links>
          ))}
        </div>
      </div>
      <div className="hidden max-lg:block xs:flex flex-col relative">
        <Image src={MobileImage} width={0} height={0} sizes="100vw" style={{width: '100%', height: 'auto'}} alt="mobile-image" />
      </div>
    </div>
  );
};
export default NavigationCategory;
